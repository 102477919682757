import * as url from './url'

import {
  request,
  httpRequestMethods
} from '@/api'

export const generateCommonRequest = function (name) {
  return {
    get: function (parm) {
      return request(url.common.get(name), httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(url.common.add(name), httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(url.common.update(name, id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(url.common.del(name, id), httpRequestMethods.DELETE)
    }
  }
}

// assets
export const assetsRequest = Object.assign(generateCommonRequest('assets'), {
  getProcess (id) {
    return request('/internalcontrol/assets/' + id, httpRequestMethods.GET)
  }
})

// assetsCheckRecord
export const assetsCheckRecordRequest = {
  get (parm) {
    return request('/internalcontrol/assets_check_record', httpRequestMethods.GET, parm)
  },
  check (parm) {
    return request('/internalcontrol/assets_check_record', httpRequestMethods.POST, parm)
  }
}

// goods
export const goodsRequest = generateCommonRequest('goods')

// assetsType
export const assetsTypeRequest = Object.assign(generateCommonRequest('assets_type'), {
  getTree (parm) {
    return request('/internalcontrol/assets_type/tree', httpRequestMethods.GET, parm)
  }
})

// assetsIn
export const assetsInRequest = generateCommonRequest('assets_in')

// assetsRepair
export const assetsRepairRequest = generateCommonRequest('assets_repair')

// assetsRepairApply
export const assetsRepairApplyRequest = Object.assign(generateCommonRequest('assets_repair_apply'), {
  switchStatus (id, parm) {
    return request('/internalcontrol/assets_repair_apply/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/internalcontrol/assets_repair_apply/by_auth', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/internalcontrol/assets_repair_apply/my_org', httpRequestMethods.GET, parm)
  },
  files (id, parm) {
    return request('/internalcontrol/assets_repair_apply/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getUpcoming () {
    return request('/internalcontrol/assets_repair_apply/upcoming', httpRequestMethods.POST)
  }
})

// assetsScrapApply
export const assetsScrapApplyRequest = Object.assign(generateCommonRequest('assets_scrap_apply'), {
  switchStatus (id, parm) {
    return request('/internalcontrol/assets_scrap_apply/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/internalcontrol/assets_scrap_apply/by_auth', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/internalcontrol/assets_scrap_apply/my_org', httpRequestMethods.GET, parm)
  },
  files (id, parm) {
    return request('/internalcontrol/assets_scrap_apply/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getUpcoming () {
    return request('/internalcontrol/assets_scrap_apply/upcoming', httpRequestMethods.POST)
  }
})

// budgetYear
export const budgetYearRequest = {
  get (parm) {
    return request(url.budgetYear.get, httpRequestMethods.GET, parm)
  },
  getMy (parm) {
    return request('/internalcontrol/budget/year/my', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request(url.budgetYear.add, httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request(url.budgetYear.update(id), httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request(url.budgetYear.update(id), httpRequestMethods.DELETE)
  },
  files (id, parm) {
    return request('/internalcontrol/budget/year/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  switchStatus (id, status) {
    return request('/internalcontrol/budget/year/switch/' + id, httpRequestMethods.POST, { statusActionKey: status })
  },
  getUpcoming () {
    return request('/internalcontrol/budget/year/upcoming', httpRequestMethods.POST)
  },
  getInfo (parm) {
    return request('/internalcontrol/budget/year/info', httpRequestMethods.GET, parm)
  },
  getMyInfo (parm) {
    return request('/internalcontrol/budget/year/info/my', httpRequestMethods.GET, parm)
  }
}

// budget
export const budgetRequest = {
  get (parm) {
    return request('/internalcontrol/budget', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/internalcontrol/budget/my_org', httpRequestMethods.GET, parm)
  },
  getAll (parm) {
    return request('/internalcontrol/budget/all', httpRequestMethods.GET, parm)
  }
}

// budgetDetail
export const budgetDetailRequest = {
  get (parm) {
    return request('/internalcontrol/budget/detail', httpRequestMethods.GET, parm)
  },
  getByAuth (parm) {
    return request('/internalcontrol/budget/detail/by_auth', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request(url.budgetDetail.add, httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request(url.budgetDetail.update(id), httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request(url.budgetDetail.update(id), httpRequestMethods.DELETE)
  },
  switchStatus (id, parm) {
    return request('/internalcontrol/budget/detail/switch/' + id, httpRequestMethods.POST, parm)
  },
  getInfo (parm) {
    return request('/internalcontrol/budget/detail/info', httpRequestMethods.GET, parm)
  },
  getInfoByAuth (parm) {
    return request('/internalcontrol/budget/detail/info/by_auth', httpRequestMethods.GET, parm)
  }
}

// purchaseContract
export const purchaseContractRequest = Object.assign(generateCommonRequest('purchase_contract'), {
  getMyOrg (parm) {
    return request('/internalcontrol/purchase_contract/my_org', httpRequestMethods.GET, parm)
  }
})

// purchase
export const purchaseRequest = Object.assign(generateCommonRequest('purchase'), {
  getMyOrg (parm) {
    return request('/internalcontrol/purchase/my_org', httpRequestMethods.GET, parm)
  }
})

export const statusManageRequest = {
  switchConfig (parm) {
    return request('/internalcontrol/status_manage/switch_config', httpRequestMethods.GET, parm)
  },
  config (parm) {
    return request('/internalcontrol/status_manage/config', httpRequestMethods.GET, parm)
  },
  reloadGroup () {
    return request('/internalcontrol/status_manage/reload', httpRequestMethods.GET)
  },
  getGroup () {
    return request('/internalcontrol/status_manage', httpRequestMethods.GET)
  },
  updateGroup (parm) {
    return request('/internalcontrol/status_manage', httpRequestMethods.POST, parm)
  },
  delGroup (id) {
    return request('/internalcontrol/status_manage/' + id, httpRequestMethods.DELETE)
  },
  updateStatus (parm) {
    return request('/internalcontrol/status_manage/status', httpRequestMethods.POST, parm)
  },
  delStatus (id) {
    return request('/internalcontrol/status_manage/status/' + id, httpRequestMethods.DELETE)
  },
  addSwitch (parm) {
    return request('/internalcontrol/status_manage/switch', httpRequestMethods.POST, parm)
  },
  updateSwitch (id, parm) {
    return request('/internalcontrol/status_manage/switch/' + id, httpRequestMethods.PUT, parm)
  },
  delSwitch (id) {
    return request('/internalcontrol/status_manage/switch/' + id, httpRequestMethods.DELETE)
  },
  addAction (parm) {
    return request('/internalcontrol/status_manage/action', httpRequestMethods.POST, parm)
  },
  updateAction (id, parm) {
    return request('/internalcontrol/status_manage/action/' + id, httpRequestMethods.PUT, parm)
  },
  delAction (id) {
    return request('/internalcontrol/status_manage/action/' + id, httpRequestMethods.DELETE)
  },
  addCondition (parm) {
    return request('/internalcontrol/status_manage/condition', httpRequestMethods.POST, parm)
  },
  updateCondition (id, parm) {
    return request('/internalcontrol/status_manage/condition/' + id, httpRequestMethods.PUT, parm)
  },
  delCondition (id) {
    return request('/internalcontrol/status_manage/condition/' + id, httpRequestMethods.DELETE)
  }
}

export const purchaseBatchRequest = Object.assign(generateCommonRequest('purchase_batch'), {
  getMy (parm) {
    return request('/internalcontrol/purchase_batch/my', httpRequestMethods.GET, parm)
  },
  switchStatus (id, parm) {
    return request('/internalcontrol/purchase_batch/switch/' + id, httpRequestMethods.POST, parm)
  },
  files (id, parm) {
    return request('/internalcontrol/purchase_batch/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getUpcoming () {
    return request('/internalcontrol/purchase_batch/upcoming', httpRequestMethods.POST)
  }
})

export const purchaseBatchDetailsRequest = Object.assign(generateCommonRequest('purchase_batch/detail'), {
  switchStatus (id, parm) {
    return request('/internalcontrol/purchase_batch/detail/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/internalcontrol/purchase_batch/detail/by_auth', httpRequestMethods.GET, parm)
  }
})

export const purchaseMethodBatchRequest = Object.assign(generateCommonRequest('purchase_method_batch'), {
  getMy (parm) {
    return request('/internalcontrol/purchase_method_batch/my', httpRequestMethods.GET, parm)
  },
  switchStatus (id, parm) {
    return request('/internalcontrol/purchase_method_batch/switch/' + id, httpRequestMethods.POST, parm)
  },
  files (id, parm) {
    return request('/internalcontrol/purchase_method_batch/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getUpcoming () {
    return request('/internalcontrol/purchase_method_batch/upcoming', httpRequestMethods.POST)
  }
})

export const purchaseMethodBatchDetailsRequest = Object.assign(generateCommonRequest('purchase_method_batch/detail'), {
  switchStatus (id, parm) {
    return request('/internalcontrol/purchase_method_batch/detail/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/internalcontrol/purchase_method_batch/detail/by_auth', httpRequestMethods.GET, parm)
  },
  files (id, parm) {
    return request('/internalcontrol/purchase_method_batch/detail/file_ids/' + id, httpRequestMethods.PUT, parm)
  }
})

export const billRequest = Object.assign(generateCommonRequest('bill'), {
  check (id) {
    return request('/internalcontrol/bill/check/' + id, httpRequestMethods.POST)
  },
  getCheck (parm) {
    return request('/internalcontrol/bill/check', httpRequestMethods.GET, parm)
  },
  manualCheck (id, parm) {
    return request('/internalcontrol/bill/manual_check/' + id, httpRequestMethods.POST, parm)
  },
  checkTimes (parm) {
    return request('/internalcontrol/bill/check/times', httpRequestMethods.GET, parm)
  },
  addCheckTimes (parm) {
    return request('/internalcontrol/bill/add_times', httpRequestMethods.POST, parm)
  },
  checkTimesCount () {
    return request('/internalcontrol/bill/check/times/count', httpRequestMethods.GET)
  }
})

export const acceptanceRequest = Object.assign(generateCommonRequest('acceptance'), {
  accept (id, parm) {
    return request('/internalcontrol/acceptance/accept/' + id, httpRequestMethods.POST, parm)
  },
  getMy (parm) {
    return request('/internalcontrol/acceptance/my_org', httpRequestMethods.GET, parm)
  },
  getMyOrgCreate (parm) {
    return request('/internalcontrol/acceptance/my_org_create', httpRequestMethods.GET, parm)
  },
  getUpcoming () {
    return request('/internalcontrol/acceptance/upcoming', httpRequestMethods.POST)
  }
})
