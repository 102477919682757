export const envs = {
  dev: {
    env: 'yi',
    webPort: '',
    sysList: ['hrms', 'internalcontrol'],
    hostName: 'dev.yi.e-forward.cn',
    apiAdr: 'https://api.dev.yi.e-forward.cn',
    remark: 'yi',
    ipc: '皖ICP备2021009694号',
    address: '安徽省安庆市太湖县法华路51号'
  },
  demo: {
    env: 'hrms_demo',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'demo.e-forward.cn',
    apiAdr: 'https://api.demo.e-forward.cn',
    remark: 'hrms_demo',
    ipc: '皖ICP备2021009694号'
  },
  nk: {
    env: 'hrms_nk',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'neikong.e-forward.cn',
    apiAdr: 'https://api.neikong.e-forward.cn',
    remark: 'hrms_nk',
    ipc: '皖ICP备2021009694号'
  },
  test: {
    env: 'hrms_test',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'test.e-forward.cn',
    apiAdr: 'https://api.test.e-forward.cn',
    remark: 'hrms_test',
    ipc: '皖ICP备2021009694号'
  },
  pro: {
    env: 'hrms_pro',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'pro.e-forward.cn',
    apiAdr: 'https://api.pro.e-forward.cn',
    remark: 'hrms_pro',
    ipc: '皖ICP备2021009694号'
  },
  aqsfy: {
    env: 'aqsfy',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'aqsfy.e-forward.cn',
    apiAdr: 'https://api.aqsfy.e-forward.cn',
    remark: 'aqsfy',
    ipc: '皖ICP备2021009694号'
  },
  tongcheng: {
    env: 'hrms_tongcheng',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'tongcheng.e-forward.cn',
    apiAdr: 'https://api.tongcheng.e-forward.cn',
    remark: 'hrms_tongcheng',
    ipc: '皖ICP备2021009694号'
  },
  tongchengpro: {
    env: 'hrms_tongcheng_pro',
    webPort: '',
    sysList: ['hrms'],
    hostName: '192.168.0.252',
    apiAdr: 'http://192.168.0.252:8780',
    remark: 'hrms_tongcheng'
  },
  tongchengproWw: {
    env: 'hrms_tongcheng_pro_ww',
    webPort: '',
    sysList: ['hrms'],
    hostName: '112.53.233.75',
    apiAdr: 'http://112.53.233.75:8780',
    remark: 'hrms_tongcheng_pro_ww'
  },
  aqthfy: {
    env: 'aqthfy',
    webPort: '',
    sysList: ['hrms', 'internalcontrol'],
    hostName: 'thfy.yi.e-forward.cn',
    apiAdr: 'https://api.thfy.yi.e-forward.cn',
    docStepList: ['主要领导批示', '分管领导批示', '职能科室', '拟办意见', '办理情况'],
    remark: 'aqthfy',
    title: '太湖县妇幼保健院',
    ipc: '皖ICP备2021009694号',
    address: '安徽省安庆市太湖县法华路51号'
  },
  aqthfy2: {
    env: 'aqthfy2',
    webPort: '',
    sysList: ['hrms', 'internalcontrol'],
    hostName: 'thfy.e-forward.cn',
    apiAdr: 'https://api.thfy.yi.e-forward.cn',
    docStepList: ['主要领导批示', '分管领导批示', '职能科室', '拟办意见', '办理情况'],
    remark: 'aqthfy2',
    title: '太湖县妇幼保健院',
    ipc: '皖ICP备2021009694号',
    address: '安徽省安庆市太湖县法华路51号'
  },
  thxgaj: {
    env: 'thxgaj',
    webPort: '',
    sysList: ['internalcontrol'],
    hostName: 'thxgaj.e-forward.cn',
    apiAdr: 'https://api.thxgaj.e-forward.cn',
    remark: 'aqthfy',
    sysThemes: ['blue'],
    themes: [4],
    defaultTheme: 4,
    defaultSysTheme: 'blue',
    ipc: '皖ICP备2021009694号'
  },
  taihuZyy: {
    env: 'hrms_taihuzyy',
    webPort: '',
    sysList: ['hrms'],
    hostName: '172.16.109.56',
    apiAdr: 'http://172.16.109.56:8780',
    remark: 'hrms_taihuzyy',
    defaultTheme: 5,
    themes: [5],
    sysThemes: ['yellow'],
    defaultSysTheme: 'yellow',
    cmpName: '太湖县中医院智能人事管理平台'
  },
  susong: {
    env: 'hrms_susong',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'susong.e-forward.cn',
    apiAdr: 'https://api.susong.e-forward.cn',
    remark: 'hrms_susong',
    ipc: '皖ICP备2021009694号'
  },
  susongPro: {
    env: 'susongPro',
    webPort: '',
    sysList: ['hrms'],
    hostName: '192.168.10.253',
    apiAdr: 'http://192.168.10.253:8780',
    remark: 'susongPro'
  },
  yuexipro: {
    env: 'hrms_yuexi_pro',
    webPort: '',
    sysList: ['hrms'],
    hostName: '112.30.157.41',
    apiAdr: 'http://112.30.157.41:8780',
    remark: 'hrms_yuexi_pro'
  },
  huainingpro: {
    env: 'huainingpro',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'ahhnfy.e-forward.cn',
    apiAdr: 'https://api.ahhnfy.e-forward.cn',
    remark: 'huainingpro',
    ipc: '皖ICP备2021009694号'
  },
  test1: {
    env: 'test_hrms',
    webPort: '8080',
    sysList: ['hrms', 'internalcontrol'],
    hostName: 'localhost',
    apiAdr: 'https://api.dev.yi.e-forward.cn',
    // apiAdr: 'http://127.0.0.1:8780',
    remark: 'test_hrms',
    ipc: '皖ICP备2021009694号',
    address: '安徽省安庆市太湖县法华路51号'
  },
  test2: {
    env: 'hrms_demo',
    webPort: '8081',
    sysList: ['hrms'],
    hostName: 'localhost',
    apiAdr: 'https://api.dev.e-forward.cn',
    remark: 'hrms_demo',
    ipc: '皖ICP备2021009694号'
  },
  thjkzx: {
    env: 'hrms_thjkzx',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'thjkzx.e-forward.cn',
    apiAdr: 'https://api.thjkzx.e-forward.cn',
    remark: 'hrms_thjkzx',
    ipc: '皖ICP备2021009694号'
  },
  aqwjfy: {
    env: 'aqwjfy',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'aqwjfy.e-forward.cn',
    apiAdr: 'https://api.aqwjfy.e-forward.cn',
    remark: 'aqwjfy',
    ipc: '皖ICP备2021009694号',
    title: '望江县妇幼保健计划生育服务中心',
    address: '安徽省安庆市望江县逸仙路'
  },
  thxzgw: {
    env: 'thxzgw',
    webPort: '',
    sysList: ['hrms'],
    hostName: 'thxzgw.e-forward.cn',
    apiAdr: 'https://api.thxzgw.e-forward.cn',
    remark: 'thxzgw',
    ipc: '皖ICP备2021009694号'
  },
  thxwjw: {
    env: 'thxwjw',
    webPort: '',
    sysList: ['internalcontrol'],
    hostName: 'thxwjw.e-forward.cn',
    apiAdr: 'https://thxwjw.e-forward.cn',
    remark: 'thxwjw',
    sysThemes: ['blue'],
    themes: [4],
    defaultTheme: 4,
    defaultSysTheme: 'blue',
    ipc: '皖ICP备2021009694号'
  },
}
  
const initEnv = () => {
  // 根据前端接口来判断环境
  let env = null
  let hostName = window.location.hostname
  let useEnvs = Object.keys(envs).filter(key => envs[key].hostName === hostName)
  let webPort = window.location.port
  if (useEnvs.length > 0) {
    env = envs[useEnvs[0]]
    if (useEnvs.length > 1) {
      let useEnvKey = useEnvs.find(key => envs[key].webPort === webPort)
      if (useEnvKey) {
        env = envs[useEnvKey]
      }
    }
  }
  console.log(env ? ('环境:' + env.remark) : '获取环境失败, 使用' + envs['dev'].remark)
  return env || envs['test1']
}

export default initEnv()
