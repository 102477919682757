export const worker = [{
  path: 'inforate',
  name: 'worker.inforate',
  component: () => import('../views/worker/infoRate')
},
{
  path: 'worker',
  name: 'worker.worker',
  component: () => import('../views/worker/worker')
},
{
  path: 'edit',
  name: 'worker.edit',
  component: () => import('../views/person/edit_info/page')
},
{
  path: 'worker_in',
  name: 'worker.worker_in',
  component: () => import('../views/worker/workerIn')
},
{
  path: 'worker_idcard',
  name: 'worker.worker_idcard',
  component: () => import('../views/worker/workerIdCard')
},
{
  path: 'worker_edit',
  name: 'worker.worker_edit',
  component: () => import('../views/worker/workerEdit')
},
{
  path: 'worker_out',
  name: 'worker.worker_out',
  component: () => import('../views/worker/workerOut')
},
{
  path: 'his_work',
  name: 'worker.his_work',
  component: () => import('../views/worker/hisWork')
},
{
  path: 'agreement',
  name: 'worker.agreement',
  component: () => import('../views/worker/agreement')
},
{
  path: 'worker_edu',
  name: 'worker.worker_edu',
  component: () => import('../views/worker/workerEdu')
},
{
  path: 'worker_auth',
  name: 'worker.worker_auth',
  component: () => import('../susong/workerAuth')
},
{
  path: 'wage',
  name: 'worker.wage',
  component: () => import('../views/worker/wage')
},
{
  path: 'wage_tongcheng',
  name: 'worker.wage_tongcheng',
  component: () => import('../views/worker/wageTongcheng')
},
{
  path: 'train',
  name: 'worker.train',
  component: () => import('../views/worker/train')
},
{
  path: 'rew',
  name: 'worker.rew',
  component: () => import('../views/worker/rew')
},
{
  path: 'pun',
  name: 'worker.pun',
  component: () => import('../views/worker/pun')
},
{
  path: 'agreement_warning',
  name: 'worker.agreement_warning',
  component: () => import('../views/worker/agreementWarning')
},
{
  path: 'agreement_turn_warning',
  name: 'worker.agreement_turn_warning',
  component: () => import('../views/worker/agreementTurnWarning')
},
{
  path: 'count',
  name: 'worker.count',
  component: () => import('../views/worker/count')
},
{
  path: 'worker_job_title',
  name: 'worker.worker_job_title',
  component: () => import('../views/worker/workerJobTitle'),
},
{
  path: 'job_title_current',
  name: 'worker.job_title_current',
  component: () => import('../views/worker/jobTitleCurrent'),
},
{
  path: 'worker_job_current',
  name: 'worker.worker_job_current',
  component: () => import('../views/worker/workerJobTitleCurrent'),
},
{
  path: 'worker_certificate',
  name: 'worker.worker_certificate',
  component: () => import('../views/worker/workerCertificate'),
},
{
  path: 'worker_certificate_other',
  name: 'worker.worker_certificate_other',
  component: () => import('../views/worker/workerCertificateOther'),
},
{
  path: 'worker_certificate_doctor_pra',
  name: 'worker.worker_certificate_doctor_pra',
  component: () => import('../views/worker/workerCertificateDoctorPra')
},
{
  path: 'worker_certificate_doctor_pra_count',
  name: 'worker.worker_certificate_doctor_pra_count',
  component: () => import('../views/worker/workerCertificateDoctorPraCount')
},
{
  path: 'worker_certificate_doctor_qua',
  name: 'worker.worker_certificate_doctor_qua',
  component: () => import('../views/worker/workerCertificateDoctorQua')
},
{
  path: 'worker_certificate_pro_hire',
  name: 'worker.worker_certificate_pro_hire',
  component: () => import('../views/worker/workerCertificateProHire'),
},
{
  path: 'job_title_promotion',
  name: 'worker.job_title_promotion',
  component: () => import('../views/worker/workerJobTitlePromotion'),
},
{
  path: 'job_title_queue',
  name: 'worker.job_title_queue',
  component: () => import('../views/worker/workerJobTitleQueue'),
},
{
  path: 'job_title_queue_warning',
  name: 'worker.job_title_queue_warning',
  component: () => import('../views/worker/workerJobTitleQueueWarning'),
}]

export const activity = [
  {
    path: 'type',
    name: 'activity.type',
    component: () => import('../views/activity/type'),
  },
  {
    path: 'manage',
    name: 'activity.manage',
    component: () => import('../views/activity/manage'),
  },
  {
    path: 'my',
    name: 'activity.my',
    component: () => import('../views/activity/my'),
  },
  {
    path: 'detail',
    name: 'activity.detail',
    component: () => import('../views/activity/detail')
  },
  {
    path: 'show',
    name: 'activity.show',
    component: () => import('../views/activity/show'),
  }
]

export const article = [
  {
    path: 'type',
    name: 'article.type',
    component: () => import('../views/article/type'),
  },
  {
    path: 'manage',
    name: 'article.manage',
    component: () => import('../views/article/manage'),
  },
  {
    path: 'my',
    name: 'article.my',
    component: () => import('../views/article/my'),
  },
  {
    path: 'detail',
    name: 'article.detail',
    component: () => import('../views/article/detail')
  }
]

export const askLeave = [
  {
    path: 'apply',
    name: 'ask_leave.apply',
    component: () => import('../views/askleave/apply'),
  },
  {
    path: 'apply_assign',
    name: 'ask_leave.apply_assign',
    component: () => import('../views/askleave/applyAssign'),
  },
  {
    path: 'apply_record',
    name: 'ask_leave.apply_record',
    component: () => import('../views/askleave/applyRecord'),
  },
  {
    path: 'record',
    name: 'ask_leave.record',
    component: () => import('../views/askleave/record')
  },
  {
    path: 'my',
    name: 'ask_leave.my',
    component: () => import('../views/askleave/my')
  },
  {
    path: 'home',
    name: 'ask_leave.home',
    component: () => import('../views/askleave/home')
  },
  {
    path: 'switch_config',
    name: 'ask_leave.switch_config',
    component: () => import('../views/askleave/switchConfig')
  }
]

export const base = [
  {
    path: 'job_title',
    name: 'base.job_title',
    component: () => import('../views/base/jobTitle'),
  },
  {
    path: 'certificate',
    name: 'base.certificate',
    component: () => import('../views/base/certificate'),
  },
  {
    path: 'status',
    name: 'base.status',
    component: () => import('../views/status/index'),
  }
]

export const appraisal = [
  {
    path: 'manage',
    name: 'appraisal.manage',
    component: () => import('../views/appraisal/manage'),
  },
  {
    path: 'ready',
    name: 'appraisal.ready',
    component: () => import('../views/appraisal/ready'),
  },
  {
    path: 'doing',
    name: 'appraisal.doing',
    component: () => import('../views/appraisal/doing'),
  },
  {
    path: 'checking',
    name: 'appraisal.checking',
    component: () => import('../views/appraisal/checking'),
  },
  {
    path: 'question',
    name: 'appraisal.question',
    component: () => import('../views/appraisal/question'),
  },
  {
    path: 'vote',
    name: 'appraisal.vote',
    component: () => import('../views/appraisal/vote'),
  },
  {
    path: 'my',
    name: 'appraisal.my',
    component: () => import('../views/appraisal/my'),
  }
]

export const doc = [
  {
    path: 'manage',
    name: 'doc.manage',
    component: () => import('../views/doc/manage')
  },
  {
    path: 'home',
    name: 'doc.home',
    component: () => import('../views/doc/home')
  },
  {
    path: 'handle',
    name: 'doc.handle',
    component: () => import('../views/doc/handle')
  },
  {
    path: 'detail',
    name: 'doc.detail',
    component: () => import('../views/doc/detail')
  },
  {
    path: 'manage_hywj',
    name: 'doc.manage_hywj',
    component: () => import('../views/doc/manageHywj')
  },
  {
    path: 'handle_hywj',
    name: 'doc.handle_hywj',
    component: () => import('../views/doc/handleHywj')
  },
  {
    path: 'manage_qtwj',
    name: 'doc.manage_qtwj',
    component: () => import('../views/doc/manageQtwj')
  }
]

export const issues = [
  {
    path: 'manage',
    name: 'issues.manage',
    component: () => import('../views/issues/manage')
  },
  {
    path: 'handle',
    name: 'issues.handle',
    component: () => import('../views/issues/handle')
  },
  {
    path: 'detail',
    name: 'issues.detail',
    component: () => import('../views/issues/detail')
  }
]

export const count = [
  {
    path: 'base',
    name: 'count.base',
    component: () => import('../views/count/base')
  },
  {
    path: 'home',
    name: 'count.home',
    component: () => import('../views/count/home')
  },
  {
    path: 'count',
    name: 'count.count',
    component: () => import('../views/count/count')
  }
]

export const resume = [
  {
    path: 'index',
    name: 'resume.index',
    component: () => import('../views/resume/index')
  },
  {
    path: 'details',
    name: 'resume.details',
    component: () => import('../views/resume/details'),
    children: [
      {
        path: 'base',
        name: 'resume.details.base',
        component: () => import('../views/resume/details/base'),
      },
      {
        path: 'work',
        name: 'resume.details.work',
        component: () => import('../views/resume/details/work'),
      },
      {
        path: 'edu',
        name: 'resume.details.edu',
        component: () => import('../views/resume/details/edu'),
      },
      {
        path: 'job',
        name: 'resume.details.job',
        component: () => import('../views/resume/details/job'),
      },
      {
        path: 'rew',
        name: 'resume.details.rew',
        component: () => import('../views/resume/details/rew'),
      },
      {
        path: 'train',
        name: 'resume.details.train',
        component: () => import('../views/resume/details/train'),
      },
      {
        path: 'certificate',
        name: 'resume.details.certificate',
        component: () => import('../views/resume/details/certificate/index'),
      },
      {
        path: 'certificatePra',
        name: 'resume.details.certificatePra',
        component: () => import('../views/resume/details/certificate/pra'),
      },
      {
        path: 'certificateQua',
        name: 'resume.details.certificateQua',
        component: () => import('../views/resume/details/certificate/qua'),
      },
      {
        path: 'certificateOther',
        name: 'resume.details.certificateOther',
        component: () => import('../views/resume/details/certificate/other'),
      },
      {
        path: 'all',
        name: 'resume.details.all',
        component: () => import('../views/resume/details/all'),
      }
    ]
  },
  {
    path: 'preview',
    name: 'resume.preview',
    component: () => import('../views/resume/preview')
  },
  {
    path: 'print',
    name: 'resume.print',
    component: () => import('../views/resume/print')
  }
]

export const report = [
  { path: 'nucleic/print', name: 'report.nucleic.print', component: () => import('../views/report/nucleic/print') },
  { path: 'nucleic/data', name: 'report.nucleic.data', component: () => import('../views/report/nucleic/data') },
  { path: 'nucleic/result', name: 'report.nucleic.result', component: () => import('../views/report/nucleic/result') },
  { path: 'nucleic/counts', name: 'report.nucleic.counts', component: () => import('../views/report/nucleic/counts') }
]

export const analysis = [
  { path: 'age', name: 'analysis.age', component: () => import('../views/analysis/age') },
  { path: 'medical', name: 'analysis.medical', component: () => import('../views/analysis/medical') },
  { path: 'position', name: 'analysis.position', component: () => import('../views/analysis/pool/position') },
  { path: 'political_status', name: 'analysis.political_status', component: () => import('../views/analysis/pool/politicalStatus') },
  { path: 'type', name: 'analysis.type', component: () => import('../views/analysis/pool/type') },
  { path: 'job_type', name: 'analysis.job_type', component: () => import('../views/analysis/pool/jobType') },
  { path: 'edu', name: 'analysis.edu', component: () => import('../views/analysis/pool/edu') },
  { path: 'job_level', name: 'analysis.job_level', component: () => import('../views/analysis/pool/jobLevel') },
  { path: 'job_title', name: 'analysis.job_title', component: () => import('../views/analysis/pool/jobTitle') },
]

export const evaluation = [
  {
    path: 'index',
    name: 'evaluation.index',
    component: () => import('../views/evaluation/index')
  },
  {
    path: 'details',
    name: 'evaluation.details',
    component: () => import('../views/evaluation/details'),
    children: [
      {
        path: 'draw',
        name: 'evaluation.details.draw',
        props: { type: 'draw' },
        component: () => import('../views/evaluation/details/draw')
      },
      {
        path: 'opinion',
        name: 'evaluation.details.opinion',
        props: { type: 'opinion' },
        component: () => import('../views/evaluation/details/draw')
      },
      {
        path: 'approve',
        name: 'evaluation.details.approve',
        props: { type: 'approve' },
        component: () => import('../views/evaluation/details/approve')
      }
    ]
  }
]
