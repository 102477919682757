export default {
  getMyDoc: {
    funKey: 'getMyDoc',
    name: '查看我的公文',
    apis: [
      { method: 'GET', path: '/basis/official_document_handle/my' }
    ]
  },
  getDetail: {
    funKey: 'getDetail',
    name: '查看公文详情',
    apis: [
      { method: 'GET', path: '/basis/official_document/detail' },
      { method: 'GET', path: '/basis/worker/all' },
      { method: 'GET', path: '/fms/download' },
      { method: 'GET', path: '/fms/file' }
    ]
  },
  saveDetails: {
    funKey: 'saveDetails',
    name: '人员安排',
    apis: [
      { method: 'POST', path: '/basis/official_document' },
      { method: 'GET', path: '/basis/worker/all' }
    ]
  },
  putDetails: {
    funKey: 'putDetails',
    name: '编辑公文批示',
    apis: [
      { method: 'PUT', path: '/basis/official_document_handle/{id}' },
      { method: 'GET', path: '/ucenter/sys_config' },
      { method: 'POST', path: '/ucenter/sys_config' },
      { method: 'DELETE', path: '/ucenter/sys_config/{id}' }
    ]
  },
  manage: {
    funKey: 'manage',
    name: '公文管理',
    apis: [
      { method: 'POST', path: '/fms/upload' },
      { method: 'POST', path: '/basis/official_document' },
      { method: 'GET', path: '/basis/official_document' },
      { method: 'GET', path: '/basis/official_document/detail' },
      { method: 'DELETE', path: '/fms/file' },
      { method: 'PUT', path: '/basis/official_document/{id}' },
      { method: 'DELETE', path: '/basis/official_document/{id}' },
      { method: 'PUT', path: '/basis/official_document/back/{id}' }
    ]
  }
}